body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.red {
  background-color: red;
}
.green {
  background-color: green;
}
.blue {
  background-color: blue;
}

@media (max-width: 1600px) {
  .container {
    min-width: 100%;
  }
}
@media (min-width: 1601px) {
  .container {
    min-width: 1601px;
  }
}

@font-face {
  font-family: "ROSans";
  src: url("webfonts/ROsanswebtextregular.eot?#iefix") format("embedded-opentype"),
  url("webfonts/ROsanswebtextregular.ttf") format("opentype"),
  url("webfonts/ROsanswebtextregular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "ROSansBold";
  src: url("webfonts/ROsanswebtextbold.eot?#iefix") format("embedded-opentype"),
  url("webfonts/ROsanswebtextbold.ttf") format("opentype"),
  url("webfonts/ROsanswebtextbold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "ROSansItalic";
  src: url("webfonts/ROsanswebtextitalic.eot?#iefix") format("embedded-opentype"),
  url("webfonts/ROsanswebtextitalic.ttf") format("opentype"),
  url("webfonts/ROsanswebtextitalic.woff") format("woff");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "ROSerif";
  src: url("webfonts/ROserifwebregular.eot?#iefix") format("embedded-opentype"),
  url("webfonts/ROserifwebregular.ttf") format("opentype"),
  url("webfonts/ROserifwebregular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "ROSerifBold";
  src: url("webfonts/ROserifwebbold.eot?#iefix") format("embedded-opentype"),
  url("webfonts/ROserifwebbold.ttf") format("opentype"),
  url("webfonts/ROserifwebbold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "ROSerifItalic";
  src: url("webfonts/ROserifwebitalic.eot?#iefix") format("embedded-opentype"),
  url("webfonts/ROserifwebitalic.ttf") format("opentype"),
  url("webfonts/ROserifwebitalic.woff") format("woff");
  font-style: italic;
  font-weight: 400;
}
