// change the theme
:root {
  --primary-color: #007bc7;
}

$font-family-sans-serif: "ROSans", -apple-system, system-ui, BlinkMacSystemFont, "ROSans", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-serif:      "ROSans", Times, serif !default;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:       $font-family-sans-serif !default;
// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
